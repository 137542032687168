
import Btn from "@/components/ui/Btn.vue";
import CopyToClipboard from "@/components/ui/CopyToClipboard.vue";
import Dialog from "@/components/ui/Dialog.vue";
import ProcessedTextFragment from "@/components/ui/ProcessedTextFragment.vue";
import SlotSkeleton from "@/components/ui/skeletons/SlotSkeleton.vue";
import Timestamp from "@/components/ui/Timestamp.vue";
import { getTranslatedString as _ } from "@/i18n";
import { courseIdMixin, loadingMixin, nodeMixin } from "@/mixins";
import { CourseTreeNode as ICourseTreeNode, FileNode, AnnouncementNode } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import CourseTreeNodeCommentSection from "../CourseTreeNodeCommentSection.vue";
import CourseTreeNode from "../node/CourseTreeNode.vue";
import { nodeEmits, nodeProps } from "../shared";
export default defineComponent({
	name: "AnnouncementNodeDetail",
	mixins: [loadingMixin, nodeMixin, courseIdMixin],
	props: {
		node: {
			type: Object as PropType<AnnouncementNode>,
			required: true,
		},
		children: {
			type: Array as PropType<ICourseTreeNode[]>,
			default: () => [],
		},
		...nodeProps,
	},
	emits: {
		...nodeEmits,
	},
	created() {
		// TODO possibly extract shared logic
		this.$emit("loadChildren", { node: this.node, fromFirstPage: true });
		this.$emit("loadComments", this.node);
	},
	methods: {
		onEdit() {
			this.$emit("editNode", this.node);
		},
	},
	computed: {},
	components: {
		Timestamp,
		ProcessedTextFragment,
		Btn,
		SlotSkeleton,
		CourseTreeNode,
		CopyToClipboard,
		CourseTreeNodeCommentSection,
	},
});
